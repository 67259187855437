<template>
    <div class="dealing_box">
        <el-form
            label-position="left"
            size="small"
            label-width="80px"
            v-for="item in dealingsList"
            :key="item.id"
        >
            <el-form-item label="往来时间">
                {{ item.intercourseTime }}
                <span class="add_explain" @click="showDialog(item.id)"
                    >添加说明</span
                >
            </el-form-item>
            <el-form-item label="往来标题">
                {{ item.intercourseTitle }}
            </el-form-item>
            <el-form-item label="跟进人">
                {{ item.adminName }}
            </el-form-item>
            <el-form-item label="联系方式">
                {{ $tableDataHandle.contactWay(item) }}
            </el-form-item>
            <el-form-item label="往来内容">
                <div class="brand">
                    {{ item.intercourseContent }}
                </div>
            </el-form-item>
            <el-form-item
                label="说明"
                v-if="item.describeList && item.describeList.length > 0"
            >
                <div
                    v-for="(remark, index) in item.describeList"
                    style="margin-bottom: 6px"
                >
                    <span>{{ remark.describeContent }}</span>
                </div>
            </el-form-item>
        </el-form>
        <!-- 往来添加说明 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加说明
                </div>
            </template>
            <div class="dialog_info">
                <el-form label-width="70px" label-position="left" size="small">
                    <el-form-item label="说明内容" required>
                        <el-input
                            type="textarea"
                            rows="6"
                            placeholder="请输入说明内容"
                            v-model="describeContent"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer" class="dialog_footer">
                <el-button @click="dialog = false" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="addExplain"
                    round
                    size="mini"
                    style="border-color: #2370eb; background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { intercourseAdd } from '@/api/clue/meClue';
export default {
    data() {
        return {
            dialog: false,
            describeId: 0,
            describeContent: '',

            dealingsList: [],
        };
    },
    methods: {
        getData(data) {
            this.dealingsList = data;
        },
        showDialog(id) {
            this.dialog = true;
            this.describeId = id;
        },
        addExplain() {
            if (!this.describeContent) {
                return this.$message.error('请输入说明');
            }
            let data = {
                param: {
                    describeContent: this.describeContent,
                    intercourseId: this.describeId,
                },
            };
            intercourseAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.handleClose();
                    this.describeContent = '';
                }
            });
        },
        handleClose() {
            this.dialog = false;
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.dealing_box {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    .el-form {
        padding: 5px 10px 0 20px;

        .el-form-item {
            margin-bottom: 6px !important;
        }
        .el-form-item:first-child {
            position: relative;
            /deep/ .el-form-item__content {
                padding-right: 76px;
            }
        }
        .el-form-item:first-child:before {
            position: absolute;
            content: '';
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background: #2370eb;
            border-radius: 50%;
        }
        /deep/ .el-form-item__label {
            color: #666 !important;
            font-weight: normal !important;
        }
        /deep/ .el-form-item__content {
            color: #333 !important;
            font-weight: bold !important;
        }
    }
    .el-form + .el-form {
        border-top: 1px dashed #e5e5e5;
    }
    .brand {
        background: #f0f4ff;
        border-radius: 4px;
        padding: 4px;
        margin-left: -4px;
    }
}
.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.add_explain {
    position: absolute;
    right: 5px;
    top: 3px;
    font-weight: normal;
    display: block;
    font-size: 12px;
    color: #ffa802;
    padding: 5px 10px;
    border: 1px solid #ffa802;
    line-height: 1;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
}
</style>
