import http from '@/utils/http.js'

// 标签列表
export function labelList(data) {
    return http({
        url: "/jasoboss/tag/list",
        method: "post",
        data
    })
}
// 标签列表
export function manageList(data) {
    return http({
        url: "/jasoboss/tag/manage/list",
        method: "post",
        data
    })
}
// 标签编辑
export function tagUpdate(data) {
    return http({
        url: "/jasoboss/tag/update",
        method: "post",
        data
    })
}
// 标签删除
export function tagDelete(data) {
    return http({
        url: "/jasoboss/tag/delete",
        method: "post",
        data
    })
}
// 添加标签
export function addLabel(data) {
    return http({
        url: "/jasoboss/tag/add",
        method: "post",
        data
    })
}
// 获取所属标签
export function relationList(data) {
    return http({
        url: "/jasoboss/tag/relation/list",
        method: "post",
        data
    })
}
// 添加绑定标签
export function relationLabel(data) {
    return http({
        url: "/jasoboss/tag/relation/add",
        method: "post",
        data
    })
}
// 删除绑定标签
export function relationDelete(data) {
    return http({
        url: "/jasoboss/tag/relation/delete",
        method: "post",
        data
    })
}
