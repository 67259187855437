<template>
    <div class="add">
        <div class="from-box">
            <div class="left">
                <p
                    style="
                        font-size: 14px;
                        margin-bottom: 15px;
                        margin-top: 10px;
                        color: #fa8c15;
                    "
                >
                    基本信息
                </p>
                <CustomerForm
                    ref="customerForm"
                    @inputCompanyName="inputCompanyName"
                />
            </div>
            <div class="right">
                <ContactsForm ref="contactsForm" />
            </div>
        </div>
        <div class="bottom-but">
            <span style="margin-left: auto">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 20px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >保存</el-button
                ></span
            >
        </div>
    </div>
</template>
<script>
import ContactsForm from './contactsForm.vue';
import CustomerForm from './customerForm.vue';
import { addCustomer } from '@/api/user/ae/customer';
import { relationList, relationDelete } from '@/api/user/label.js';
export default {
    components: {
        ContactsForm,
        CustomerForm,
    },
    data() {
        return {
            type: '',
            customerDO: {},
            customerContactList: [],
        };
    },
    methods: {
        getData(i, data) {
            console.log(data);
            this.$refs.customerForm.inputCompanyName(data.companyName);
            for (let j in data) {
                if (data[j] === 0) {
                    data[j] = '';
                }
            }
            this.$refs.customerForm.customerDO.companyName = data.companyName;

            this.$refs.customerForm.customerDO.companyDomain =
                data.companyDomain;
            this.$refs.customerForm.customerDO.primaryEmailBrand =
                data.primaryEmailBrand;
            if (i == 1) {
                this.$refs.customerForm.customerDO.enterpriseType =
                    data.companyType;
                this.$refs.customerForm.customerDO.customerExpireTime =
                    data.companyExpireTime;
                if (data.lineDomainBOList && data.lineDomainBOList.length > 0) {
                    this.$refs.customerForm.customerDomainList =
                        data.lineDomainBOList;
                }
                if (
                    data.lineContactBOList &&
                    data.lineContactBOList.length > 0
                ) {
                    this.$refs.customerForm.customerDO.customerMainContactList =
                        data.lineContactBOList;
                }
            }
            if (i == 2) {
                this.$refs.customerForm.customerDO.customerExpireTime =
                    data.customerExpireTime;
                if (
                    data.customerDomainList &&
                    data.customerDomainList.length > 0
                ) {
                    this.$refs.customerForm.customerDomainList =
                        data.customerDomainList;
                }
                if (
                    data.customerMainContactList &&
                    data.customerMainContactList.length > 0
                ) {
                    this.$refs.customerForm.customerDO.customerMainContactList =
                        data.customerMainContactList;
                }
            }
            this.$refs.customerForm.customerDO.emailVersionType =
                data.emailVersionType;
            this.$refs.customerForm.customerDO.emailVersionTypeName =
                data.emailVersionTypeName;
            this.$refs.customerForm.customerDO.business = data.business;
            this.$refs.customerForm.customerDO.estimateTerm = data.estimateTerm;
            this.$refs.customerForm.customerDO.estimateNumber =
                data.estimateNumber;
            this.$refs.customerForm.customerDO.address = data.address;
            this.$refs.customerForm.customerDO.contactRemark =
                data.contactRemark;
            this.$refs.customerForm.customerDO.remark = data.remark;
            if (
                data.customerContactList &&
                data.customerContactList.length > 0
            ) {
                this.$refs.contactsForm.customerContactList =
                    data.customerContactList;
            } else {
                this.$refs.contactsForm.customerContactList = [
                    {
                        customerContactWayDOList: [
                            {
                                contactWay: 1,
                                contactContent: '',
                            },
                        ],
                        companyName: '',
                    },
                ];
            }

            if (data.cityId == 0) {
                this.$refs.customerForm.address = [String(data.provinceId)];
            } else {
                this.$refs.customerForm.address = [
                    String(data.provinceId),
                    String(data.cityId),
                ];
            }
            this.getLabelData(data);
        },
        // 获取绑定标签
        getLabelData(obj) {
            let data = {
                param: {
                    bandingId: obj.id,
                    bandingTableType: 2,
                },
            };
            relationList(data).then((res) => {
                this.$refs.customerForm.customerTagList = res.data.list;
            });
        },
        onSubmit() {
            if (!this.$refs.customerForm.tag) {
                return this.$message.error('本客户名称重复，请修改');
            }
            if (!this.$refs.customerForm.$data.customerDO.enterpriseType) {
                return this.$message.error('请选择客户类型');
            }
            if (!this.$refs.customerForm.$data.customerDO.companyName) {
                return this.$message.error('请输入公司名称');
            }
            for (let j in this.$refs.customerForm.$data.customerDO
                .customerMainContactList) {
                if (
                    !this.$refs.customerForm.$data.customerDO
                        .customerMainContactList[j].contactContent
                ) {
                    this.$refs.customerForm.$data.customerDO.customerMainContactList.splice(
                        j,
                        1
                    );
                }
            }
            for (let i in this.$refs.contactsForm.$data.customerContactList) {
                if (
                    !this.$refs.contactsForm.$data.customerContactList[i]
                        .contactName
                ) {
                    return this.$message.error('请输入联系人');
                }

                for (let j in this.$refs.contactsForm.$data.customerContactList[
                    i
                ].customerContactWayDOList) {
                    if (
                        !this.$refs.contactsForm.$data.customerContactList[i]
                            .customerContactWayDOList[j].contactWay ||
                        !this.$refs.contactsForm.$data.customerContactList[i]
                            .customerContactWayDOList[j].contactContent
                    ) {
                        return this.$message.error('请输入完整联系人联系方式');
                    }
                }
            }
            // console.log(this.$refs.contactsForm.$data);
            this.customerDO = this.$refs.customerForm.$data.customerDO;
            this.customerDO.provinceId = Number(
                this.$refs.customerForm.$data.address[0]
            );
            this.customerDO.customerExpireTime =
                this.customerDO.customerExpireTime;
            this.customerDO.cityId = Number(
                this.$refs.customerForm.$data.address[1]
            );
            this.customerDO.customerTagList =
                this.$refs.customerForm.customerTagList;
            this.customerContactList =
                this.$refs.contactsForm.$data.customerContactList;

            if (this.type != '') {
                this.customerDO.customerType = this.type;
            }
            let data = {
                param: {
                    customerDO: this.customerDO,
                    customerTagList: this.$choiceLabel.state.labelList,
                    customerDomainList:
                        this.$refs.customerForm.$data.customerDomainList,
                    customerContactList: this.customerContactList,
                    lineDO: {
                        id: this.$route.query.id,
                    },
                },
            };
            addCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        getCustomerType(type) {
            this.type = type;
        },
        // 客户基本信息公司名称输入
        inputCompanyName(val) {
            this.$refs.contactsForm.$data.customerContactList.forEach(
                (item) => {
                    item.companyName = val;
                }
            );
        },
        onClose() {
            this.$emit('close');
            this.$choiceLabel.commit('emptyData');
            this.type = '';
            this.$refs.customerForm.$data.customerDO = {
                customerMainContactList: [
                    { contactWay: 1, contactContent: '', contactName: '' },
                ],
            };
            this.$refs.customerForm.$data.customerDomainList = [{}];
            this.$refs.contactsForm.$data.customerContactList = [
                {
                    customerContactWayDOList: [
                        { contactWay: 1, contactContent: '' },
                    ],
                    companyName: '',
                },
            ];
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add {
    width: 100%;
    height: 100%;
    .from-box {
        background: #f0f2f4;
        overflow: auto;
        .left {
            flex: 1;
            background: #fff;
            padding: 0 10px 0 30px;
            overflow: auto;
            border-bottom: 1px solid #e5e5e5;
        }
        .right {
            flex: 1;
            background: #fff;
            padding: 0 30px;
            overflow: auto;
        }
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
        button {
            width: 90px;
        }
    }
}
</style>
