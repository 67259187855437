<template>
    <div class="contacts_form">
        <div v-for="(item, index) in customerContactList" :key="index">
            <el-form label-width="90px" label-position="left" size="small">
                <p class="contacts_form_title">
                    联系人信息
                    <span @click="addCustomerContact()" v-if="index == 0"
                        ><i class="el-icon-plus"></i>添加联系人</span
                    >
                    <span @click="removeCustomerContact(index)" v-else
                        ><i class="el-icon-delete"></i>删除联系人</span
                    >
                </p>
                <el-form-item label="联系人" required>
                    <el-input v-model="item.contactName" placeholder="请输入"></el-input>
                </el-form-item>

                <el-form-item label="联系方式" required>
                    <div
                        class="info contact_way_box"
                        v-for="(itm, idx) in item.customerContactWayDOList"
                        :key="idx"
                    >
                        <el-button
                            v-if="idx > 0"
                            type="primary"
                            @click="toTop(index, idx)"
                            class="el-icon-top ordinary"
                            circle
                            size="mini"
                        ></el-button>
                        <el-select
                            v-model="itm.contactWay"
                            size="small"
                            style="width:85px"
                            placeholder="方式"
                        >
                            <el-option label="电话" :value="1"></el-option>
                            <el-option label="微信" :value="2"></el-option>
                            <el-option label="邮箱" :value="3"></el-option>
                            <el-option label="QQ" :value="4"></el-option>
                            <el-option label="短信" :value="5"></el-option>
                            <el-option label="拜访" :value="6"></el-option>
                            <!-- <el-option label="官网表单" :value="8"></el-option> -->
                            <el-option label="座机" :value="9"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                        <el-input
                            style="margin-left:5px;width:150px"
                            v-model="itm.contactContent"
                            placeholder="请输入"
                            size="small"
                        ></el-input>
                        <el-button
                            v-if="idx == 0"
                            @click="addContact(index)"
                            type="primary"
                            class="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="idx > 0"
                            @click.prevent="removeContact(index, idx)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: [],
    data() {
        return {
            index: '',
            labelType: '',
            customerContactList: [
                {
                    customerContactWayDOList: [
                        {
                            contactWay: 1,
                            contactContent: '',
                        },
                    ],
                    companyName: '',
                },
            ],
        };
    },

    methods: {
        // 新增联系人
        addCustomerContact() {
            this.customerContactList.push({
                customerContactWayDOList: [{ contactWay: '', contactContent: '' }],
            });
            this.customerContactList = JSON.parse(JSON.stringify(this.customerContactList));
        },
        removeCustomerContact(index) {
            this.customerContactList.splice(index, 1);
        },
        addContact(index) {
            this.customerContactList[index].customerContactWayDOList.push({});

            this.customerContactList = JSON.parse(JSON.stringify(this.customerContactList));
        },
        removeContact(index, idx) {
            this.customerContactList[index].customerContactWayDOList.splice(idx, 1);
        },
        // 移除标签
        onDeleteLabel(index, idx) {
            this.$choiceLabel.commit('removeMailLabel', [index, idx]);
            this.$choiceLabel.state.MaillabelList = JSON.parse(
                JSON.stringify(this.$choiceLabel.state.MaillabelList),
            );
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        // 选择标签
        onChoiceLabels(i) {
            this.dialogChoice = true;
            let that = this;
            // console.log(i);
            setTimeout(() => {
                that.isGet = !that.isGet;
                that.index = i;
                this.$choiceLabel.commit('isMultiple', true);
                this.$choiceLabel.commit('getType', '3');
            }, 200);
        },
        handleClose() {
            this.dialogChoice = false;
        },

        // 置顶
        toTop(index, idx) {
            let arr = this.customerContactList[index].customerContactWayDOList.splice(idx, 1);
            // console.log(arr);
            this.customerContactList[index].customerContactWayDOList.unshift(arr[0]);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contacts_form {
    margin-top: 10px;
    .contact_way_box {
        background-color: #fff;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 0;
        margin-bottom: 10px;
        position: relative;
    }
    /deep/ .el-textarea__inner {
        resize: none;
    }
    .contacts_form_title {
        font-size: 14px;
        margin-bottom: 15px;
        color: #2370eb;
        span {
            color: #2370eb;
            font-size: 12px;
            border-radius: 2px;
            border: 1px solid #2370eb;
            float: right;
            padding: 2px 8px;
            cursor: pointer;
        }
    }
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
.el-input {
    width: 240px;
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
.el-button--mini.is-circle.ordinary {
    color: #999999;
    border-color: #999999;
    margin-left: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.el-button--mini.is-circle.ordinary:hover {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
}
</style>
