<template>
    <div class="clue">
        <div class="form">
            <div style="flex: 1">
                <el-form
                    label-width="90px"
                    label-position="left"
                    size="small"
                    style="padding: 16px 35px"
                >
                    <el-form-item label="线索产品" required>
                        <el-cascader
                            size="small"
                            ref="cascader"
                            v-model="form.productClue"
                            :options="productListData"
                            :style="{ width: '80%' }"
                            :show-all-levels="false"
                            clearable
                            placeholder="请选择线索产品"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            @change="
                                (val) => {
                                    form.launchId = '';
                                    getLaunchList(val);
                                }
                            "
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                value: 'id',
                                label: 'productName',
                                children: 'children',
                            }"
                        >
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="渠道分类" required>
                        <el-radio
                            v-model="form.sourceCategory"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            :label="1"
                            >市场</el-radio
                        >
                    </el-form-item>
                    <el-form-item
                        label="投放站点"
                        v-if="form.sourceCategory == 1"
                    >
                        <el-select
                            v-model="form.launchId"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 2 &&
                                returnDis() != 4
                            "
                            style="width: 80%"
                            placeholder="请选择投放站点"
                            clearable
                        >
                            <el-option
                                v-for="item in launchList"
                                :key="item.id"
                                :label="item.account"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="线索类型"
                        required
                        v-if="form.sourceCategory == 1"
                    >
                        <el-select
                            v-model="form.clueType"
                            size="small"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 2 &&
                                returnDis() != 4
                            "
                            style="width: 80%"
                            clearable
                            placeholder="线索类型"
                        >
                            <el-option label="售前咨询" :value="1"></el-option>
                            <el-option label="购买咨询" :value="2"></el-option>
                            <el-option label="售后" :value="3"></el-option>
                            <el-option
                                label="基础版注册"
                                :value="4"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="留资方式"
                        required
                        v-if="form.sourceCategory == 1"
                    >
                        <el-select
                            v-model="form.retentionMethod"
                            size="small"
                            style="width: 80%"
                            clearable
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 2 &&
                                returnDis() != 4
                            "
                            placeholder="留资方式"
                        >
                            <el-option label="添加微信" :value="1"></el-option>
                            <el-option label="400电话" :value="2"></el-option>
                            <el-option label="在线留言" :value="3"></el-option>
                            <el-option label="官网表单" :value="4"></el-option>
                            <el-option label="邮件" :value="5"></el-option>
                            <el-option label="自拓" :value="6"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <el-form
                    label-width="90px"
                    label-position="left"
                    size="small"
                    style="padding: 0px 35px; margin-top: -10px"
                    :disabled="disabled"
                >
                    <el-form-item label="客户名称">
                        <el-input
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            v-model="form.companyName"
                            placeholder="请输入客户名称"
                        ></el-input>
                        <el-button
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            @click="onHistory()"
                            type="text"
                            class="iconfont icon-ziyuanxhdpi"
                            circle
                            style="color: rgb(35, 112, 235); font-size: 18px"
                        ></el-button>
                    </el-form-item>
                    <el-form-item label="域名">
                        <el-input
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            v-model="form.companyDomain"
                            placeholder="请输入域名"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="关联域名">
                        <div
                            class="info contact_way_box"
                            :key="index"
                            v-for="(item, index) in form.lineDomainDOList"
                        >
                            <el-input
                                v-model="item.domain"
                                placeholder="请输入关联域名"
                                size="small"
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                            ></el-input>
                            <el-button
                                v-if="index == 0"
                                @click="addDomain"
                                type="primary"
                                class="el-icon-plus"
                                circle
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                                size="mini"
                            ></el-button>
                            <el-button
                                v-if="index > 0"
                                @click.prevent="removeDomain(index)"
                                class="el-icon-minus"
                                type="primary"
                                circle
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                                size="mini"
                            ></el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="预计用户数">
                        <el-select
                            size="small"
                            v-model="form.estimateNumber"
                            placeholder="请选择预计用户数"
                            style="width: 80%"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            clearable
                        >
                            <el-option
                                v-for="item in $MailStatus.estimateNumber()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计年限">
                        <el-select
                            size="small"
                            v-model="form.estimateTerm"
                            placeholder="请选择预计用户数"
                            style="width: 80%"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            clearable
                        >
                            <el-option
                                v-for="item in $MailStatus.estimateTerm()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="原邮箱品牌">
                        <el-select
                            v-model="form.primaryEmailBrand"
                            size="small"
                            style="width: 80%"
                            clearable
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            placeholder="请选择原邮箱品牌"
                        >
                            <el-option
                                v-for="item in $searchForm.mailboxbrand()"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属行业">
                        <el-select
                            v-model="form.business"
                            size="small"
                            style="width: 80%"
                            clearable
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            placeholder="请选择所属行业"
                        >
                            <el-option
                                v-for="item in $industry.industry()"
                                :label="item.label"
                                :value="item.value"
                                :key="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在地">
                        <el-cascader
                            size="small"
                            style="width: 80%"
                            clearable
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            :options="$address.cascader()"
                            v-model="addressArr"
                            placeholder="请选择所在地"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="联系备注">
                        <el-input
                            v-model="form.contactRemark"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            placeholder="请输入联系备注"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div style="flex: 1">
                <el-form
                    label-width="90px"
                    label-position="left"
                    size="small"
                    style="padding: 16px 35px"
                >
                    <el-form-item label="需求" required>
                        <el-input
                            style="width: 80%"
                            type="textarea"
                            :rows="3"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            placeholder="请输入需求"
                            v-model="form.remark"
                        >
                        </el-input>
                    </el-form-item>
                </el-form>

                <el-form
                    label-width="90px"
                    label-position="left"
                    size="small"
                    style="padding: 0px 35px; margin-top: -10px"
                >
                    <el-form-item label="客户类型">
                        <el-radio
                            v-model="form.companyType"
                            :label="1"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            >企业</el-radio
                        >
                        <el-radio
                            v-model="form.companyType"
                            :label="2"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            >个人</el-radio
                        >
                        <el-radio
                            v-model="form.companyType"
                            :label="3"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            >外资</el-radio
                        >
                    </el-form-item>
                    <el-form-item label="客户标签">
                        <span
                            :key="index"
                            :class="item.category == 0 ? 'blue' : 'yellow'"
                            class="labelList"
                            v-for="(item, index) in customerTagList"
                            >{{ item.tagName }}
                            <span
                                v-if="
                                    !(
                                        returnDis() != 1 &&
                                        returnDis() != 3 &&
                                        returnDis() != 4
                                    )
                                "
                                class="cancel"
                                @click="onDeleteLabel(item)"
                            >
                                <span class="el-icon-close"></span>
                            </span>
                        </span>
                        <el-button
                            v-if="
                                !(
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                )
                            "
                            type="primary"
                            icon="el-icon-plus"
                            size="small"
                            @click="onChoiceLabel"
                            style="
                                background: rgba(0, 0, 0, 0.02);
                                border-color: rgba(0, 0, 0, 0.15);
                                color: #000000;
                                padding: 9px;
                            "
                        ></el-button>
                    </el-form-item>
                    <el-form-item
                        label="客户"
                        v-if="form.followAdminId == adminId"
                    >
                        <span v-if="form.customerBOList.length > 0">{{
                            form.customerBOList[0].companyName
                        }}</span>
                        <el-button
                            v-if="
                                form.customerBOList.length > 0 &&
                                form.status == 5
                            "
                            type="primary"
                            size="small"
                            style="
                                background: #2370eb;
                                border-color: #2370eb;
                                color: #fff;
                                padding: 9px;
                                margin-left: 10px;
                            "
                            @click="onUpdateCus"
                        >
                            变更
                        </el-button>
                        <el-button
                            v-else
                            type="primary"
                            size="small"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            style="
                                background: #2370eb;
                                border-color: #2370eb;
                                color: #fff;
                                padding: 9px;
                            "
                            @click="onAddCust()"
                        >
                            创建客户
                        </el-button>
                    </el-form-item>
                    <el-form-item label="联系方式">
                        <div
                            class="info contact_way_box"
                            v-for="(item, index) in form.lineContactDOList"
                            :key="index"
                            style="width: 100%"
                        >
                            <el-select
                                v-model="item.contactWay"
                                size="small"
                                clearable
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                                placeholder="方式"
                            >
                                <el-option label="电话" :value="1"></el-option>
                                <el-option label="微信" :value="2"></el-option>
                                <el-option label="邮箱" :value="3"></el-option>
                                <el-option label="QQ" :value="4"></el-option>
                                <el-option label="短信" :value="5"></el-option>
                                <el-option label="拜访" :value="6"></el-option>
                                <!--<el-option label="官网表单" :value="8"></el-option> -->
                                <el-option label="座机" :value="9"></el-option>
                                <el-option label="其他" :value="7"></el-option>
                            </el-select>
                            <el-input
                                style="margin-left: 5px"
                                v-model="item.contactContent"
                                placeholder="号码"
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                                size="small"
                            ></el-input>
                            <el-input
                                style="margin-left: 5px"
                                v-model="item.contactName"
                                placeholder="联系人"
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                                size="small"
                            ></el-input>
                            <el-button
                                v-if="index == 0"
                                @click="addContact"
                                type="primary"
                                class="el-icon-plus"
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                                circle
                                size="mini"
                            ></el-button>
                            <el-button
                                v-if="index > 0"
                                @click.prevent="removeContact(index)"
                                class="el-icon-minus"
                                type="primary"
                                :disabled="
                                    returnDis() != 1 &&
                                    returnDis() != 3 &&
                                    returnDis() != 4
                                "
                                circle
                                size="mini"
                            ></el-button></div
                    ></el-form-item>
                    <el-form-item
                        v-if="
                            form.followAdminId == adminId &&
                            form.customerBOList &&
                            form.customerBOList.length > 0
                        "
                    >
                        <!-- <el-button
                            type="primary"
                            style="
                                background: #fff;
                                border-radius: 30px;
                                padding: 0 12px 0 12px;
                                height: 30px;
                                color: #409eff;
                                font-size: 12px;
                            "
                            @click="onSyncContact()"
                        >
                            <i class="el-icon-refresh"></i>
                            同步至客户联系人
                        </el-button> -->
                    </el-form-item>
                    <el-form-item label="到期时间">
                        <el-date-picker
                            v-model="form.companyExpireTime"
                            size="small"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width:80%;x"
                            type="date"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="地址">
                        <el-input
                            v-model="form.address"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            placeholder="请输入地址"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            v-model="form.lineRemark"
                            :disabled="
                                returnDis() != 1 &&
                                returnDis() != 3 &&
                                returnDis() != 4
                            "
                            placeholder="请输入备注"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                ref="choiceLabel"
                @close="handleClose"
                @addLabel="addLabel"
                :isGet="isGet"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable
                ref="addlable"
                @close="handleAddlabel"
                @addLabel="addLabel"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogRepeat"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    创建客户
                </div>
            </template>
            <CustRepeat
                @close="handleClose"
                ref="custrepeat"
                @tableRadio="tableRadio"
            />
        </el-dialog>
        <!-- 客户 -->
        <el-dialog
            :visible.sync="dialogCustomer"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    客户
                </div>
            </template>
            <div class="dialog_info">
                <el-form
                    label-position="left"
                    style="margin: 0 auto; width: 80%"
                    size="small"
                >
                    <p
                        class="info"
                        v-if="
                            form.customerBOList &&
                            form.customerBOList.length > 0
                        "
                    >
                        <label>原客户</label>
                        {{ form.customerBOList[0].companyName }}
                    </p>
                    <el-form-item label="新客户" required>
                        <el-select
                            v-model="customerId"
                            size="small"
                            filterable
                            style="width: 270px"
                            placeholder="请搜索或输入客户名称"
                            :filter-method="getCustomerList"
                        >
                            <el-option
                                v-for="item in customerListData"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    @click="relationCustomer"
                    round
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { launchList } from '@/api/user/launch/manage.js';
import { clueGet } from '@/api/clue/meClue.js';
import { customerList } from '@/api/customer/customer.js';
import { getCustomerAdmin } from '@/api/user/ae/customer.js';
import ChoiceLabel from './label/choiceLabel.vue';
import AddLable from './label/addLabel.vue';
import CustRepeat from './custRepeat.vue';
import { relationCustomer } from '@/api/user/clue.js';
import { contactAdd } from '@/api/user/ae/customer';
import { relationList, relationDelete } from '@/api/user/label.js';
import { productList } from '@/api/product/product.js';
export default {
    components: {
        ChoiceLabel,
        AddLable,
        CustRepeat,
    },
    data() {
        return {
            form: {
                launchId: '',
            },
            addressArr: [],
            launchList: [],
            customerTagList: [],
            dialogChoice: false,
            dialogAddlabel: false,
            isGet: false,
            dialogRepeat: false,
            adminId: sessionStorage.getItem('adminId'),
            disabled: true,
            isMarket: true,
            customerId: '',
            dialogCustomer: false,
            customerListData: [],
            productListData: [],
        };
    },
    methods: {
        getData(data) {
            for (let i in data) {
                if (data[i] === 0) {
                    data[i] = '';
                }
            }
            let initalObj = Object.assign({}, data);

            if (
                !initalObj.lineDomainBOList ||
                initalObj.lineDomainBOList.length == 0
            ) {
                initalObj.lineDomainDOList = [{ domain: '' }];
            } else {
                initalObj.lineDomainDOList = initalObj.lineDomainBOList;
            }

            if (
                !initalObj.lineContactBOList ||
                initalObj.lineContactBOList.length == 0
            ) {
                initalObj.lineContactDOList = [
                    {
                        contactWay: 1,
                    },
                ];
            } else {
                initalObj.lineContactDOList = initalObj.lineContactBOList;
            }

            if (initalObj.cityId == 0) {
                this.addressArr = [String(initalObj.provinceId)];
            } else {
                this.addressArr = [
                    String(initalObj.provinceId),
                    String(initalObj.cityId),
                ];
            }
            this.form = initalObj;
            console.log(initalObj);
            this.getLaunchList(initalObj.productClue);
            if (this.form.adminId == sessionStorage.getItem('adminId')) {
                if (
                    this.form.status == 12 ||
                    this.form.status == 13 ||
                    this.form.status == 14
                ) {
                    this.disabled = false;
                    this.isMarket = false;
                }
                if (this.form.status == 5) {
                    this.disabled = true;
                    this.isMarket = false;
                }
            }
            if (
                this.form.followAdminId == sessionStorage.getItem('adminId') &&
                this.form.status == 5
            ) {
                this.disabled = false;
            }
            if (
                this.form.lineSynergyList &&
                this.form.lineSynergyList.length > 0
            ) {
                for (let i = 0; i < this.form.lineSynergyList.length; i++) {
                    if (
                        this.form.lineSynergyList[i].synergyAdminId ==
                            sessionStorage.getItem('adminId') &&
                        this.form.status == 5
                    ) {
                        this.disabled = false;
                        break;
                    }
                }
            }
            let data2 = {
                param: {
                    status: 1,
                },
                pageSize: 0,
                pageNum: 0,
            };
            productList(data2).then((res) => {
                this.productListData = this.$Tree.treeDataTranslate(
                    res.data.list,
                    'id',
                    'parentId',
                    false
                );
            });
            this.getLabelData();
            this.returnDis();
        },
        // 同步至客户联系人
        onSyncContact() {
            let lineContactDOList = Object.assign(
                [],
                this.form.lineContactDOList
            );
            let customerContactList = Object.assign(
                [],
                this.form.customerBOList[0].customerContactList
            );
            for (let i = 0; i < lineContactDOList.length; i++) {
                console.log(lineContactDOList[i].contactName);
                if (
                    !lineContactDOList[i].contactContent ||
                    !lineContactDOList[i].contactName
                ) {
                    // lineContactDOList.splice(i, 1);
                    return this.$message.error('请先完善联系人信息');
                }
                for (let j = 0; j < customerContactList.length; j++) {
                    console.log(lineContactDOList[i]);
                    if (
                        lineContactDOList[i] &&
                        lineContactDOList[i].contactName &&
                        lineContactDOList[i].contactName ==
                            customerContactList[j].contactName
                    ) {
                        for (
                            let k = 0;
                            k <
                            customerContactList[j].customerContactWayDOList
                                .length;
                            k++
                        ) {
                            if (
                                lineContactDOList[i].contactContent ==
                                customerContactList[j].customerContactWayDOList[
                                    k
                                ].contactContent
                            ) {
                                lineContactDOList.splice(i, 1);
                                i = i - 1;
                            }
                        }
                    }
                }
            }
            if (lineContactDOList.length == 0) {
                return this.$message.error('已同步，请勿重复操作');
            }
            for (let l = 0; l < lineContactDOList.length; l++) {
                let data = {
                    param: {
                        customerContactDO: {
                            contactName: lineContactDOList[l].contactName,
                            customerContactWayDOList: [
                                {
                                    contactContent:
                                        lineContactDOList[l].contactContent,
                                    contactWay: lineContactDOList[l].contactWay,
                                },
                            ],
                        },
                    },
                };
                data.param.companyName =
                    this.form.customerBOList[0].companyName;
                data.param.customerContactDO.customerId =
                    this.form.customerBOList[0].id;
                contactAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('同步成功');
                        this.$emit('relationOk');
                    }
                });
            }

            // console.log(lineContactDOList);
        },
        // 投放列表
        getLaunchList(productId) {
            let data = {
                param: { status: 1 },
                pageNum: 0,
                pageSize: 0,
            };
            if (productId) {
                data.param.productId = productId;
            }
            launchList(data).then((res) => {
                if (res.code == 200) {
                    this.launchList = res.data.list;
                }
            });
        },
        // 创建客户
        onAddCust() {
            if (this.form.companyName && this.form.companyName != '') {
                let data = {
                    param: {
                        name: this.form.companyName,
                    },
                };
                customerList(data).then((res) => {
                    if (res.code == 200) {
                        if (
                            res.data.list &&
                            res.data.list.length > 0 &&
                            this.form.companyType == 1
                        ) {
                            this.dialogRepeat = true;
                            setTimeout(() => {
                                this.$refs.custrepeat.getData(res.data.list);
                            });
                        } else {
                            this.$emit('addCust', 1);
                        }
                    }
                });
            } else {
                this.$emit('addCust', 1);
            }
        },
        onUpdateCus() {
            sessionStorage.setItem('customerlist', 1);
            this.dialogCustomer = true;
            this.customerList();
        },
        getCustomerList(val) {
            this.customerList(val);
        },
        customerList(name) {
            let data = {
                param: {
                    adminId: this.adminId,
                },
                pageSize: 10,
                pageNum: 1,
            };
            if (name) {
                data.param.name = name;
            }
            getCustomerAdmin(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.customerListData = res.data.list;
                }
            });
        },
        relationCustomer() {
            let data = {
                param: {
                    customerDO: {
                        id: this.customerId,
                    },
                    lineDO: {
                        id: this.form.id,
                    },
                    oldCustomerId: this.form.customerBOList[0].id,
                },
            };
            relationCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('getBtn');
                    this.customerId = '';
                    this.handleClose();
                }
            });
        },
        tableRadio() {
            this.$emit('relationOk');
            this.dialogRepeat = false;
        },
        getCustomerBOList(data) {
            this.form.customerBOList = data;
        },
        // 添加域名
        addDomain() {
            this.form.lineDomainDOList.push({});
        },
        // 删除域名
        removeDomain(index) {
            this.form.lineDomainDOList.splice(index, 1);
        },
        // 添加联系方式
        addContact() {
            this.form.lineContactDOList.push({
                contactWay: 1,
                contactContent: '',
                contactName: '',
            });
        },
        // 删除联系方式
        removeContact(index) {
            this.form.lineContactDOList.splice(index, 1);
        },
        // 获取绑定标签
        getLabelData() {
            let data = {
                param: {
                    bandingId: this.form.id,
                    bandingTableType: 1,
                },
            };
            relationList(data).then((res) => {
                this.customerTagList = res.data.list;
            });
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
            setTimeout(() => {
                this.$refs.addlable.getType(1);
            }, 200);
        },
        // 选择标签
        onChoiceLabel() {
            // console.log(this.$choiceLabel.state.labelList);
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                this.$refs.choiceLabel.getLabel(1, this.customerTagList);
            }, 200);
        },
        // 移除标签
        onDeleteLabel(item) {
            console.log(item);
            let data = {
                param: {
                    id: item.id,
                },
            };
            relationDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    for (let i in this.customerTagList) {
                        if (this.customerTagList[i].id == item.id) {
                            this.customerTagList.splice(i, 1);
                        }
                    }
                }
            });
        },
        onHistory() {
            this.$emit('onHistory');
        },
        handleClose() {
            this.dialogChoice = false;
            this.dialogRepeat = false;
            this.dialogCustomer = false;
            sessionStorage.removeItem('customerlist');
            this.getData(this.form);
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            setTimeout(() => {
                this.$refs.choiceLabel.getLabel(1, this.customerTagList);
            }, 200);
        },
        // 判断哪个禁用
        returnDis() {
            if (
                this.form.adminId == this.adminId &&
                (this.form.status == 12 ||
                    this.form.status == 13 ||
                    this.form.status == 14)
            ) {
                return 1;
            }
            if (this.form.adminId == this.adminId && this.form.status == 5) {
                if (this.form.followAdminId == this.adminId) {
                    return 4;
                }
                return 2;
            }
            if (
                this.form.followAdminId == this.adminId &&
                this.form.status == 5
            ) {
                return 3;
            }

            // if (
            //     this.form.lineSynergyList &&
            //     this.form.lineSynergyList.length > 0
            // ) {
            //     let index = this.form.lineSynergyList.findIndex((item) => {
            //         return item.synergyAdminId == this.adminId;
            //     });
            //     if (index == -1) {
            //         return 4;
            //     } else {
            //         return 5;
            //     }
            // }
        },
    },
};
</script>
<style lang="scss" scoped>
.clue {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
}

.form {
    display: flex;
    .contact_way_box {
        background-color: #fff;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 0;
        margin-bottom: 10px;
        position: relative;
        button {
            border: 2px solid #2370eb;
            background: #fff;
            color: #2370eb;

            padding: 2px;
            margin-left: 8px;
        }
    }
    .el-input {
        width: 80%;
    }
}

.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    text-align: center;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 80px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
