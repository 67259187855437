import http from '@/utils/http.js'

// 配置列表
export function configList(data) {
    return http({
        url: "/jasoboss/person/config/list",
        method: "post",
        data
    })
}
// 添加配置
export function configAdd (data) {
    return http({
        url: "/jasoboss/person/config/add",
        method: "post",
        data
    })
}