import http from '@/utils/http.js'

// 线索(列表)/413/jasoboss/clue/line/add
export function clueList(data) {
    return http({
        url: "/jasoboss/clue/line/list",
        method: "post",
        data
    })
}
// 线索添加
export function clueAdd(data) {
    return http({
        url: "/jasoboss/clue/line/add",
        method: "post",
        data
    })
}
// 线索编辑
export function clueUpDate(data) {
    return http({
        url: "/jasoboss/clue/line/update",
        method: "post",
        data
    })
}
// 线索添加协作人
export function clueAddSynergy(data) {
    return http({
        url: "/jasoboss/clue/line/add/synergy",
        method: "post",
        data
    })
}
// 线索单个
export function clueGet(data) {
    return http({
        url: "/jasoboss/clue/line/get",
        method: "post",
        data
    })
}
// 添加往来说明
export function intercourseAdd(data) {
    return http({
        url: "/jasoboss/clue/line/intercourse/describe/add",
        method: "post",
        data
    })
}
// 线索 分配部门支持多个
export function clueAllotDep(data) {
    return http({
        url: "/jasoboss/clue/line/allot/department",
        method: "post",
        data
    })
}