<template>
    <div class="custRepeat">
        <div class="content">
            <p class="title">检测到系统内已存在相同客户，是否关联客户？</p>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="tableData"
                height="100%"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column width="80">
                    <template slot-scope="scope">
                        <el-radio v-model="tableRadio" :label="scope.row"
                            ><i></i
                        ></el-radio>
                    </template>
                </el-table-column>

                <el-table-column label="创建时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            $tableDataHandle.createTime({
                                createTime: scope.row.createTime,
                            })
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="客户名称"
                    prop="companyName"
                    show-overflow-tooltip
                >
                </el-table-column>
                <!-- <el-table-column
                    label="负责人"
                    prop="followAdminName"
                    show-overflow-tooltip
                >
                </el-table-column> -->

                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 340px;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >确认</el-button
            >
        </div>
    </div>
</template>
<script>
import { relationCustomer } from '@/api/user/clue.js';
export default {
    data() {
        return {
            tableData: [],
            tableRadio: '',
        };
    },
    methods: {
        getData(data) {
            this.tableData = data;
        },
        onSubmit() {
            console.log(this.tableRadio);
            if (!this.tableRadio) {
                return this.$message.error('请选择客户');
            }
            let data = {
                param: {
                    customerDO: {
                        id: this.tableRadio.id,
                    },
                    lineDO: {
                        id: this.$route.query.id,
                    },
                },
            };
            relationCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('tableRadio');
                }
            });
        },
        onClose() {
            this.tableRadio = '';
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.custRepeat {
    display: flex;
    flex-direction: column;
    .content {
        height: 400px;
        padding: 0 50px;
        .title {
            padding: 16px 0;
            font-size: 14px;
            color: #333333;
            font-weight: 600;
        }
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
