import { render, staticRenderFns } from "./clue.vue?vue&type=template&id=5a507522&scoped=true&"
import script from "./clue.vue?vue&type=script&lang=js&"
export * from "./clue.vue?vue&type=script&lang=js&"
import style0 from "./clue.vue?vue&type=style&index=0&id=5a507522&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a507522",
  null
  
)

export default component.exports